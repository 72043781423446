<template>
  <div class="help">
    <h3 class="mt-4 mb-4">Surveys</h3>
    <p v-if="!data_submitted">
      Select and complete a survey.
    </p>
    <b-select v-model="survey_name" :options="survey_names" class="mb-4"></b-select>
    <b-form @submit="onSubmit" v-if="!data_submitted">

      <b-form-group
        id="fg-name"
        label="Your Name"
        label-for="name"
      >
        <b-form-input
          id="name"
          v-model="name"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="fg-pws-id"
        label="PWS ID (7 digits)"
        label-for="pws_id"
      >
        <b-form-input
          id="pws_id"
          v-model="pws_id"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="fg-pws-name"
        label="PWS Name"
        label-for="pws_name"
      >
        <b-form-input
          id="pws_name"
          v-model="pws_name"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="1. Do you currently add fluoride to the drinking water?"
      >
        <b-form-select v-model="fl_added" :options="['No', 'Yes', 'Suspended']" required></b-form-select>
      </b-form-group>

      <div v-if="fl_added === 'No'">

      <b-form-group
        label="1A. Have you ever added fluoride to the drinking water?"
      >
        <b-form-select v-model="fl_added_past" :options="['No', 'Yes', 'Unknown']"></b-form-select>
      </b-form-group>

      <b-form-group
        v-if="fl_added_past === 'Yes'"
        label="1B. Do you know when it was discontinued?"
      >
        <b-form-select v-model="fl_added_past_date_known" :options="['No', 'Yes']"></b-form-select>
      </b-form-group>

      <b-form-group
        v-if="fl_added_past_date_known === 'Yes'"
        label="1C. Estimated date of discontinuation"
      >
        <b-form-input type="date" v-model="fl_added_past_date" :options="['No', 'Yes']"></b-form-input>
      </b-form-group>

      </div>

      <div v-else>

      <b-form-group
        label="2. Do you currently submit a monthly fluoride operations report (MFOR) to the Health Department for each entry point where fluoride is added?"
      >
        <b-form-select v-model="fl_submit_mfor" :options="['No', 'Yes']"></b-form-select>
      </b-form-group>

      <b-form-group
        label="3. Do you currently submit a monthly split sample to the Public Health Laboratory from each entry point where fluoride is added?"
      >
        <b-form-select v-model="fl_submit_msplit" :options="['No', 'Yes']"></b-form-select>
      </b-form-group>

      <b-form-group
        label="4. Select the type of equipment currently used (may select more than one)."
      >
        <b-form-checkbox v-model="fl_equipment_saturator">SATURATOR</b-form-checkbox>
        <b-form-checkbox v-model="fl_equipment_acid_feed">LIQUID ACID-FEED SYSTEM</b-form-checkbox>
        <b-form-checkbox v-model="fl_equipment_vol_dry_feed">VOLUMETRIC DRY FEEDER</b-form-checkbox>
        <b-form-checkbox v-model="fl_equipment_grav_dry_feed">GRAVIMETRIC DRY FEEDER</b-form-checkbox>
      </b-form-group>

      <b-form-group
        label="5. Select the type of chemical used (may select more than one)."
      >
        <b-form-checkbox v-model="fl_chem_dry_naf_powder">DRY SODIUM FLUORIDE POWDER</b-form-checkbox>
        <b-form-checkbox v-model="fl_chem_granule_nasif">DRY GRANULE SODIUM FLUOROSILICATE</b-form-checkbox>
        <b-form-checkbox v-model="fl_chem_liquid_acid">LIQUID ACID</b-form-checkbox>
      </b-form-group>


      </div>

      <b-button type="submit" variant="primary">Submit Survey</b-button>
    </b-form>
    <div class="mt-3">
      <b-alert :show="data_submitted" variant="success">Thank you. Your survey submission was received. <b-link to="/">Back to Main Menu</b-link></b-alert>
    <b-alert :show="error_msg" variant="danger">There was a problem submitting this request.</b-alert>
  </div>
  </div>
</template>
<script>

export default {
    name: "Surveys",
    data() {
      return {
        survey_name: 'Fluoride Survey',
        survey_names: ['Fluoride Survey'],
        name: '',
        pws_id: '',
        pws_name: '',
        comments: '',
        phone: '',
        fl_added: 'No',
        fl_added_past: 'No',
        fl_added_past_date: '',
        fl_added_past_date_known: 'No',
        fl_submit_mfor: 'No',
        fl_submit_msplit: 'No',
        fl_equipment_saturator: false,
        fl_equipment_acid_feed: false,
        fl_equipment_vol_dry_feed: false,
        fl_equipment_grav_dry_feed: false,
        fl_chem_dry_naf_powder: false,
        fl_chem_granule_nasif: false,
        fl_chem_liquid_acid: false,
        error_msg: false,
        data_submitted: false
      }
    },
    mounted() {
      console.log('pws_id', this.pws_id)
      if (this.$store.state.pws) {
        this.pws_id = this.$store.state.pws.pws_id
        this.pws_name = this.$store.state.pws.pws_name.trim()
      }
      if (this.$store.state.user) {
        this.name = `${this.$store.state.user.last_name}, ${this.$store.state.user.first_name}`
      }
    },
    methods: {
      async onSubmit(event) {
        event.preventDefault()
        const path = process.env.VUE_APP_API_URI + "/pws-polls/fluoride-survey/" + this.pws_id
        const params = {
          poll_data: {
            name: this.name,
            pws_id: this.pws_id,
            pws_name: this.pws_name,
            fl_added: this.fl_added,
            fl_added_past: this.fl_added_past,
            fl_added_past_date: this.fl_added_past_date,
            fl_added_past_date_known: this.fl_added_past_date_known,
            fl_submit_mfor: this.fl_submit_mfor,
            fl_submit_msplit: this.fl_submit_msplit,
            fl_equipment_saturator: this.fl_equipment_saturator,
            fl_equipment_acid_feed: this.fl_equipment_acid_feed,
            fl_equipment_vol_dry_feed: this.fl_equipment_vol_dry_feed,
            fl_equipment_grav_dry_feed: this.fl_equipment_grav_dry_feed,
            fl_chem_dry_naf_powder: this.fl_chem_dry_naf_powder,
            fl_chem_granule_nasif: this.fl_chem_granule_nasif,
            fl_chem_liquid_acid: this.fl_chem_liquid_acid
          }
        }
        try {
          let response = await this.axios.post(path, params)
          console.log(response)
          if (response.data) {
            this.data_submitted = true
          }
        } catch (error) {
          this.data_submitted = false
          this.error_msg = true
          console.log(error)
        }
      }
    }
};
</script>
