<template>
  <div class="help">
    <h3 class="mt-4 mb-4">Help Request</h3>
    <p v-if="!data_submitted">
    If you need assistance with the portal, please complete the information below.  For other help requests, see table.
    </p>
    <b-form @submit="onSubmit" v-if="!data_submitted">

      <b-form-group
        id="fg-name"
        label="Name"
        label-for="name"
      >
        <b-form-input
          id="name"
          v-model="name"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="fg-pws-id"
        label="PWS ID (7 digits)"
        label-for="pws_id"
      >
        <b-form-input
          id="pws_id"
          v-model="pws_id"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="fg-pws-name"
        label="PWS Name"
        label-for="pws_name"
      >
        <b-form-input
          id="pws_name"
          v-model="pws_name"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="fg-phone"
        label="Phone Number"
        label-for="phone"
      >
        <b-form-input
          id="phone"
          v-model="phone"
        ></b-form-input>
      </b-form-group>


      <b-form-group
        id="fg-email"
        label="Email Address"
        label-for="email"
      >
        <b-form-input
          id="email"
          type="email"
          v-model="email"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="fg-comments"
        label="Comments"
        label-for="comments"
      >
        <b-form-textarea
          id="comments"
          v-model="comments"
          rows="6"
          required
        ></b-form-textarea>
      </b-form-group>
      <b-button type="submit" variant="primary">Submit Request</b-button>
    </b-form>
    <div class="mt-3">
      <b-alert :show="data_submitted" variant="success">Thank you. Someone should contact you shortly. <b-link to="/">Back to Main Menu</b-link></b-alert>
    <b-alert :show="error_msg" variant="danger">There was a problem submitting this request.</b-alert>
  </div>
  </div>
</template>
<script>

export default {
    name: "Help",
    data() {
      return {
        name: '',
        pws_id: '',
        pws_name: '',
        comments: '',
        phone: '',
        email: '',
        error_msg: false,
        data_submitted: false
      }
    },
    mounted() {
      console.log('pws_id', this.pws_id)
      if (this.$store.state.pws) {
        this.pws_id = this.$store.state.pws.pws_id
        this.pws_name = this.$store.state.pws.pws_name.trim()
      }
    },
    methods: {
      async onSubmit(event) {
        event.preventDefault()
        const path = process.env.VUE_APP_API_URI + "/support-request"
        const params = {
          name: this.name,
          phone: this.phone,
          email: this.email,
          pws_id: this.pws_id,
          pws_name: this.pws_name,
          comments: this.comments
        }
        try {
          let response = await this.axios.post(path, params)
          console.log(response)
          if (response.data) {
            this.data_submitted = true
          }
        } catch (error) {
          this.data_submitted = false
          this.error_msg = true
          console.log(error)
        }
      }
    }
};
</script>
