<template>
    <b-modal centered :id="modal_id" v-model="showModal" size="xl" :title="pdf_title" hide-footer>
      <object
        width="100%"
        :data="pdf_url"
        type="application/pdf"
	style="height:80vh"
      >
        <p>
          Unable to display PDF file.
          <a :href="pdf_url">Download</a> instead.
        </p>
      </object>
    </b-modal>
</template>

<script>
export default {
  name: "PdfModal",
  data() {
    return {
      showModal: false,
    };
  },
  props: {
    modal_id: {
      type: String,
      required: true,
    },
    pdf_url: {
      type: String,
      required: true,
    },
    pdf_title: {
      type: String,
      required: true,
    },
  },
}
</script>
