<template>
  <div class="help">
    <h3 class="mt-4 mb-4">Emergency Notice</h3>
    <p v-if="!data_submitted">
      Let us know if and how you were affected by a recent event (storm, freeze, etc.)
    </p>
    <b-form @submit="onSubmit" v-if="!data_submitted">

      <b-form-group
        id="fg-name"
        label="Your Name"
        label-for="name"
      >
        <b-form-input
          id="name"
          v-model="name"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="fg-pws-id"
        label="PWS ID (7 digits)"
        label-for="pws_id"
      >
        <b-form-input
          id="pws_id"
          v-model="pws_id"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="fg-pws-name"
        label="PWS Name"
        label-for="pws_name"
      >
        <b-form-input
          id="pws_name"
          v-model="pws_name"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="fg-phone"
        type="tel"
        label="Best Phone Number to Reach You"
        label-for="phone"
      >
        <b-form-input
          id="phone"
          v-model="phone"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="Were you affected by this event?"
      >
        <b-form-select v-model="affected" :options="['No', 'Yes']"></b-form-select>
      </b-form-group>

      <b-form-group
        label="Are you producing water on electrical power?"
      >
        <b-form-select v-model="producing_electrical" :options="['No', 'Yes']"></b-form-select>
      </b-form-group>

      <b-form-group
        label="Are you producing water on generator power?"
      >
        <b-form-select v-model="producing_generator" :options="['No', 'Yes']"></b-form-select>
      </b-form-group>

      <b-form-group label="Did the system lose pressure? If so, entire system or partial?">
        <b-form-select v-model="pressure_loss" :options="['No', 'Entire System', 'Partial']"></b-form-select>
      </b-form-group>

      <b-form-group label="Has a Boil Water Notice been issued?">
        <b-form-select v-model="boil_water" :options="['No', 'Yes']"></b-form-select>
      </b-form-group>

      <b-form-group label="Do you need help issuing a BWN?">
        <b-form-select v-model="help_bwn" :options="['No', 'Yes']"></b-form-select>
      </b-form-group>

      <b-form-group label="Do you need any assistance from our office?">
        <b-form-select v-model="other_assistance" :options="['No', 'Yes']"></b-form-select>
      </b-form-group>


      <b-form-group
        id="fg-comments"
        label="Comments"
        label-for="comments"
      >
        <b-form-textarea
          id="comments"
          v-model="comments"
          rows="6"
          required
        ></b-form-textarea>
      </b-form-group>
      <b-button type="submit" variant="primary">Submit Notice</b-button>
    </b-form>
    <div class="mt-3">
      <b-alert :show="data_submitted" variant="success">Thank you. Your notice was received. <b-link to="/">Back to Main Menu</b-link></b-alert>
    <b-alert :show="error_msg" variant="danger">There was a problem submitting this request.</b-alert>
  </div>
  </div>
</template>
<script>

export default {
    name: "Help",
    data() {
      return {
        name: '',
        pws_id: '',
        pws_name: '',
        comments: '',
        phone: '',
        affected: 'No',
        producing_electrical: 'Yes',
        producing_generator: 'No',
        pressure_loss: 'No',
        boil_water: 'No',
        help_bwn: 'No',
        other_assistance: 'No',
        error_msg: false,
        data_submitted: false
      }
    },
    mounted() {
      console.log('pws_id', this.pws_id)
      if (this.$store.state.pws) {
        this.pws_id = this.$store.state.pws.pws_id
        this.pws_name = this.$store.state.pws.pws_name.trim()
      }
      if (this.$store.state.user) {
        this.name = `${this.$store.state.user.last_name}, ${this.$store.state.user.first_name}`
      }
    },
    methods: {
      async onSubmit(event) {
        event.preventDefault()
        const path = process.env.VUE_APP_API_URI + "/emergency-notice"
        const params = {
          name: this.name,
          phone: this.phone,
          pws_id: this.pws_id,
          pws_name: this.pws_name,
          comments: this.comments,
          affected: this.affected,
          producing_electrical: this.producing_electrical,
          producing_generator: this.producing_generator,
          pressure_loss: this.pressure_loss,
          boil_water: this.boil_water,
          help_bwn: this.help_bwn,
          other_assistance: this.other_assistance
        }
        try {
          let response = await this.axios.post(path, params)
          console.log(response)
          if (response.data) {
            this.data_submitted = true
          }
        } catch (error) {
          this.data_submitted = false
          this.error_msg = true
          console.log(error)
        }
      }
    }
};
</script>
